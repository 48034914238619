import React from 'react';
import { graphql } from 'gatsby';
import IndexPage from 'components/pages/IndexPage';

const seo = {
  title:
    'Q°emotion – Análisis emocional y semántico de las opiniones de los clientes',
  keywords: [
    'Análisis emocional',
    'IA',
    'Solución SaaS',
    'Q emotion',
    'Opinión del cliente',
    'Comentarios de los clientes',
    'Comentarios',
    'Verbatim',
  ],
  description:
    '¿Qué piensan sus clientes de su marca?\n' +
    'Descubra cómo la IA y el análisis de las emociones le ayudan a analizar sus opiniones para hacer el trabajo.',
};

export default props => <IndexPage {...props} seo={seo} />;

export const pageQuery = graphql`
  query {
    dataBlockIntro: markdownRemark(
      frontmatter: {
        pageType: { eq: "indexPage" }
        blockType: { eq: "introBlock" }
        lang: { eq: "es" }
      }
    ) {
      rawMarkdownBody
      frontmatter {
        title
      }
    }
    dataBlockSubintro: markdownRemark(
      frontmatter: {
        pageType: { eq: "indexPage" }
        blockType: { eq: "subintroBlock" }
        lang: { eq: "es" }
      }
    ) {
      rawMarkdownBody
      frontmatter {
        subTitle
      }
    }
    imageSubintroBlock: allFile(
      filter: {
        relativeDirectory: { eq: "indexPage/subIntroBlock/blockImage" }
      }
      sort: { fields: [base] }
    ) {
      nodes {
        base
        childImageSharp {
          fluid(maxWidth: 550, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            presentationWidth
          }
        }
      }
    }
    dataImageMinLeftSubintro: allMarkdownRemark(
      filter: {
        frontmatter: {
          pageType: { eq: "indexPage" }
          blockType: { eq: "subintroBlock" }
          component: { eq: "imageMinLeft" }
        }
      }
      sort: { fields: [frontmatter___relatedImage] }
    ) {
      nodes {
        frontmatter {
          relatedImage
        }
      }
    }
    dataImageMinRightSubintro: allMarkdownRemark(
      filter: {
        frontmatter: {
          pageType: { eq: "indexPage" }
          blockType: { eq: "subintroBlock" }
          component: { eq: "imageMinRight" }
        }
      }
      sort: { fields: [frontmatter___relatedImage] }
    ) {
      nodes {
        frontmatter {
          relatedImage
        }
      }
    }
    dataTestimonialBlock: allMarkdownRemark(
      filter: {
        frontmatter: {
          pageType: { eq: "solutionMarketing" }
          blockType: { eq: "testimonialBlock" }
          lang: { eq: "es" }
        }
      }
      sort: { order: ASC, fields: [frontmatter___order] }
    ) {
      nodes {
        rawMarkdownBody
        frontmatter {
          author
          role
          image
          youtubeID
          youtubeTitle
        }
      }
    }
    imageTestimonialBlock: allFile(
      filter: { relativeDirectory: { eq: "bubbleImages" }, base: { in: [] } }
      sort: { fields: [id], order: [ASC] }
    ) {
      nodes {
        base
        childImageSharp {
          fluid(maxWidth: 550, quality: 100) {
            presentationWidth
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
    imagesQuotesTestimonialBlock: allFile(
      filter: {
        relativeDirectory: {
          eq: "solutionPage/analyseVerbatim/testimonialBlock"
        }
      }
    ) {
      nodes {
        base
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            presentationWidth
          }
        }
      }
    }
    imageMinSubintroBlock: allFile(
      filter: {
        relativeDirectory: { eq: "indexPage/subIntroBlock/blockImage/minImage" }
      }
      sort: { fields: [base] }
    ) {
      nodes {
        base
        childImageSharp {
          fluid(maxWidth: 100, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            presentationWidth
          }
        }
      }
    }
    imageClientSubintroBlock: allFile(
      filter: {
        relativeDirectory: { eq: "indexPage/subIntroBlock/blockClients" }
      }
      sort: { fields: [base] }
    ) {
      nodes {
        base
        childImageSharp {
          fluid(maxWidth: 120, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            presentationWidth
          }
        }
      }
    }
    dataBlockActionBody: allMarkdownRemark(
      filter: {
        frontmatter: {
          pageType: { eq: "indexPage" }
          blockType: { eq: "actionBlock" }
          lang: { eq: "es" }
          component: { eq: "block" }
        }
      }
      sort: { fields: [frontmatter___numBlockAction] }
    ) {
      nodes {
        rawMarkdownBody
        frontmatter {
          numBlockAction
          component
        }
      }
    }
    dataBlockActionTitle: markdownRemark(
      frontmatter: {
        pageType: { eq: "indexPage" }
        blockType: { eq: "actionBlock" }
        lang: { eq: "es" }
        component: { eq: "title" }
      }
    ) {
      rawMarkdownBody
    }
    imageActionBlock: file(
      relativeDirectory: { eq: "indexPage/actionBlock/es" }
    ) {
      childImageSharp {
        fluid(maxWidth: 350, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    dataBlockOperationTitle: markdownRemark(
      frontmatter: {
        pageType: { eq: "indexPage" }
        blockType: { eq: "operationBlock" }
        lang: { eq: "es" }
        component: { eq: "title" }
      }
    ) {
      rawMarkdownBody
    }
    dataBlockOperationBody: allMarkdownRemark(
      filter: {
        frontmatter: {
          pageType: { eq: "indexPage" }
          blockType: { eq: "operationBlock" }
          lang: { eq: "es" }
          component: { eq: "subTitle" }
        }
      }
      sort: { fields: [frontmatter___numBlockOperation] }
    ) {
      nodes {
        rawMarkdownBody
        frontmatter {
          numBlockOperation
          relatedImage
        }
      }
    }
    imageOperationBlock: allFile(
      filter: { relativeDirectory: { eq: "indexPage/operationBlock/es" } }
    ) {
      nodes {
        base
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            presentationWidth
          }
        }
      }
    }
    dataBlockLinkBody: allMarkdownRemark(
      filter: {
        frontmatter: {
          pageType: { eq: "indexPage" }
          blockType: { eq: "linkBlock" }
          lang: { eq: "es" }
        }
      }
      sort: { fields: [frontmatter___idBlock] }
    ) {
      nodes {
        rawMarkdownBody
        frontmatter {
          subTitle
          color
          link
          buttonText
        }
      }
    }
    dataBlockVideoTitle: markdownRemark(
      frontmatter: {
        pageType: { eq: "indexPage" }
        blockType: { eq: "videoBlock" }
        lang: { eq: "es" }
        component: { eq: "title" }
      }
    ) {
      rawMarkdownBody
    }
    dataBlockVideoBody: allMarkdownRemark(
      filter: {
        frontmatter: {
          pageType: { eq: "indexPage" }
          blockType: { eq: "videoBlock" }
          lang: { eq: "es" }
          component: { eq: "slide" }
        }
      }
      sort: { fields: [frontmatter___numSlide] }
    ) {
      nodes {
        rawMarkdownBody
        frontmatter {
          numSlide
          relatedImage
          link
        }
      }
    }
    imageVideoBlock: allFile(
      filter: { relativeDirectory: { eq: "indexPage/videoBlock/slides" } }
    ) {
      nodes {
        base
        childImageSharp {
          fluid(maxWidth: 150, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            presentationWidth
          }
        }
      }
    }
  }
`;
